<template>
  <div>
    <Navbar />
    <div class="page-wrapper">
      <div class="waiting-list-container">
        <div class="img-container">
          <img src="/img/onboarding/waiting-list-2.svg" alt="" />
        </div>
        <div class="page-header-title">
          You have been added to our waitlist!
        </div>
        <div v-if="startup.backed_by && startup.backed_by !== 'null'">
          <div class="page-sub-header">
            Our team would start working to match you with teams in our
            ecosystem.
          </div>
          <div class="page-sub-header">
            We would reach out occasionally to follow up on your proposed start
            date.
          </div>
        </div>
        <div v-else>
          <div class="page-sub-header">
            Due to legal and operational restrictions, Grupa can serve only
            ventured backed companies at this time.
          </div>
          <div class="page-sub-header">
            We will keep in touch to let you know when that changes and we’re
            able to serve everyone
          </div>
        </div>
        <div v-if="startup.backed_by && startup.backed_by !== 'null'" class="btn-container">
          <button @click="handleGoToDashboard" class="grupa-blue-btn">
            Back to Dashboard
          </button>
          <button @click="handleGoToPriority" class="grupa-white-btn">
            Skip the Line
          </button>
        </div>
        <div v-else class="btn-container">
          <a @click="handleGoToDashboard" class="grupa-blue-btn">Okay, Got it</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixPanel from "@/mixins/mixPanel";
import Navbar from "@/components/Navbar/navbar";
export default {
  mixins: [mixPanel],
  props: {},
  components: {
    Navbar,
  },

  data: () => ({}),

  created() { },
  methods: {
    handleGoToDashboard() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "project_workspace",
        params: { id },
      });
      this.mixPanelTrack({
        event: "WaitListToDashboard",
      });
    },
    handleGoToPriority() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "reserve_team",
        params: { id },
      });
      this.mixPanelTrack({
        event: "JumpToPriority",
      });
    },
  },
  computed: {
    ...mapState("clientDashboard", ["clientProject"]),
    project() {
      return this.clientProject;
    },
    startup() {
      if (this.project.startup) {
        return this.project.startup;
      } else {
        return {};
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}

.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.waiting-list-container {
  width: 390px;
  margin-top: 48px;
  text-align: left;
}

.btn-container {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.page-header-title {
  margin-top: 48px;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container img {
  width: 120px;
  height: 120px;
}

.page-sub-header {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #53585f;
  margin-top: 16px;
}
</style>
