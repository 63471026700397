var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _c("div", { staticClass: "page-wrapper" }, [
        _c("div", { staticClass: "waiting-list-container" }, [
          _vm._m(0),
          _c("div", { staticClass: "page-header-title" }, [
            _vm._v("\n        You have been added to our waitlist!\n      "),
          ]),
          _vm.startup.backed_by && _vm.startup.backed_by !== "null"
            ? _c("div", [
                _c("div", { staticClass: "page-sub-header" }, [
                  _vm._v(
                    "\n          Our team would start working to match you with teams in our\n          ecosystem.\n        "
                  ),
                ]),
                _c("div", { staticClass: "page-sub-header" }, [
                  _vm._v(
                    "\n          We would reach out occasionally to follow up on your proposed start\n          date.\n        "
                  ),
                ]),
              ])
            : _c("div", [
                _c("div", { staticClass: "page-sub-header" }, [
                  _vm._v(
                    "\n          Due to legal and operational restrictions, Grupa can serve only\n          ventured backed companies at this time.\n        "
                  ),
                ]),
                _c("div", { staticClass: "page-sub-header" }, [
                  _vm._v(
                    "\n          We will keep in touch to let you know when that changes and we’re\n          able to serve everyone\n        "
                  ),
                ]),
              ]),
          _vm.startup.backed_by && _vm.startup.backed_by !== "null"
            ? _c("div", { staticClass: "btn-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.handleGoToDashboard },
                  },
                  [_vm._v("\n          Back to Dashboard\n        ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "grupa-white-btn",
                    on: { click: _vm.handleGoToPriority },
                  },
                  [_vm._v("\n          Skip the Line\n        ")]
                ),
              ])
            : _c("div", { staticClass: "btn-container" }, [
                _c(
                  "a",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.handleGoToDashboard },
                  },
                  [_vm._v("Okay, Got it")]
                ),
              ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-container" }, [
      _c("img", {
        attrs: { src: "/img/onboarding/waiting-list-2.svg", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }